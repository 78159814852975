import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import { Customers } from "../classes"

export const CustomersPage = () => {   
    const auth = useContext(GlobalContext)   
    const [load, setLoad] = useState(true) 
    const [customers, setCustomers] = useState(new Customers()) 

    useEffect(()=>{
        customers.GetList(auth.user.userToken).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load){
        return(
            <div></div>
        )
    }

    return( 
        <>
            <div className="item-wrapper user-list">
                <div className="">
                    <div className="mp-orders__header">
                        <div className="row">
                            <div className="col-md-2">
                                id
                            </div>
                            <div className="col-md-4">
                                Доставщик
                            </div>
                            <div className="col-md-3">
                                Контактные данные
                            </div>
                            <div className="col-md-3"> 
                            </div>
                        </div>
                    </div>
                    <div className="mp-orders__list">

                        {
                            customers.list ? (
                                customers.list.length ? (
                                    customers.list.map(item => {
                                        return ( 
                                            <div className="mp-orders__item">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="grid">
                                                            <div>#{item.pk}</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="grid">
                                                            <div className="mp-orders__image-block">
                                                                <div className="mp-orders__data">
                                                                    <div className="grid">
                                                                        <div>
                                                                            {item.fName} {item.lName}<br/> 
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="grid">
                                                            <div>
                                                                <a href={`tel:${item.phone}`}>{item.phone}</a><br/>
                                                                <a href={`mailto:${item.user.email}`}>{item.user.email}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="grid">
                                                            <a className="hover-btn" href={`/customers/${item.pk}/`}>Подробнее</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                ) : ""
                            ) : ""
                        }
                    </div>
                </div>
            </div>
 
            <div className="pay-page">
                {
                    customers.countPage != 0 ? (
                        <div>
                            { 
                                customers.targetPage> 3 ? (
                                    <div onClick={()=>{
                                        customers.targetPage = 1
                                        setLoad(true)
                                        customers.GetList(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        1
                                    </div>
                                ) : ""
                            } 
                            { 
                                customers.targetPage-2 > 0 ? (
                                    <div onClick={()=>{
                                        customers.targetPage = customers.targetPage-2
                                        setLoad(true)
                                        customers.GetList(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {customers.targetPage-2}
                                    </div>
                                ) : ""
                            } 
                            { 
                                customers.targetPage-1 > 0 ? (
                                    <div onClick={()=>{
                                        customers.targetPage = customers.targetPage-1
                                        setLoad(true)
                                        customers.GetList(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {customers.targetPage-1}
                                    </div>
                                ) : "" 
                            }
                            <div className="active">
                                {customers.targetPage}
                            </div>
                            { 
                                customers.targetPage+1 <= customers.countPage ? (
                                    <div onClick={()=>{
                                        customers.targetPage = customers.targetPage+1
                                        setLoad(true)
                                        customers.GetList(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {customers.targetPage+1}
                                    </div>
                                ) : ""
                            } 
                            { 
                                customers.targetPage+2 <= customers.countPage ? (
                                    <div onClick={()=>{
                                        customers.targetPage = customers.targetPage+2
                                        setLoad(true)
                                        customers.GetList(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {customers.targetPage+2}
                                    </div>
                                ) : ""
                            } 
                            { 
                                customers.targetPage+3 <= customers.countPage ? (
                                    <div onClick={()=>{
                                        customers.targetPage = customers.countPage
                                        setLoad(true)
                                        customers.GetList(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {customers.countPage}
                                    </div>
                                ) : ""
                            } 
                        </div>
                    ) : ""
                }
            </div>
        </>
    )
}