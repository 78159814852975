import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg" 
import Delete from "../../image/delete.svg"
import Save from "../../image/save.svg"
import { GlobalContext } from "../../context/GlobalContext" 

export const SettingItem = (props: any) => {

    const [form, setForm] = useState({ 
        description: props.item.description
    })


    useEffect(()=>{
        props.options.list[props.index].description = form.description 
    },[form])

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({
            description: event.target.value
        }) 
    }

    return(
        <div className="col-md-6" key={props.index}>
            <label>
                {props.item.title}
                <input type="number" name={props.item.key} value={form.description} onChange={changeHandler} />
            </label>
        </div>
    )
}