import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import { Story } from "../classes"

export const MoneyLogPage = () => {   
    const auth = useContext(GlobalContext)   
    const [story, setStory] = useState(new Story()) 
    const [load, setLoad] = useState(true) 


    useEffect(()=>{
        story.GetStory(auth.user.userToken).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load){
        return(
            <div></div>
        )
    }
    
    return( 
        <div className="item-wrapper">
            <h2>Денежные переводы</h2>
            <div className="mp-orders__header">
                <div className="row">
                    <div className="col-1">
                        id
                    </div>
                    <div className="col">
                        Тип операции
                    </div>
                    <div className="col"> 
                        Клиент
                    </div> 
                    <div className="col">
                        Дата/время
                    </div>
                </div>
            </div>

            <div className="pay-list">
                <div className="mp-orders__list">

                    {
                        story.list ? (
                            story.list.length ? (
                                story.list.map(item => {
                                    return( 
                                        <div className="mp-orders__item">
                                            <div className="row">
                                                <div className="col-1">
                                                    <div className="grid">
                                                        <div>#{item.id}</div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="grid">
                                                        <div>
                                                            <strong>{item.title}</strong><br/>
                                                            {item.desc}
                                                        </div>
                                                    </div>
                                                </div>   
                                                <div className="col">
                                                    <div className="grid"> 
                                                        <div><strong>ID пользователя:</strong> {item.user}</div>
                                                    </div>
                                                </div>   
                                                <div className="col">
                                                    <div className="grid">
                                                        <div>{item.date}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    )
                                })
                            ) : ""
                        ) : ""
                    }
                </div>
            </div> 

            <div className="pay-page">
                {
                    story.countPage != 0 ? (
                        <div>
                            { 
                                story.targetPage> 3 ? (
                                    <div onClick={()=>{
                                        story.targetPage = 1
                                        setLoad(true)
                                        story.GetStory(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        1
                                    </div>
                                ) : ""
                            } 
                            { 
                                story.targetPage-2 > 0 ? (
                                    <div onClick={()=>{
                                        story.targetPage = story.targetPage-2
                                        setLoad(true)
                                        story.GetStory(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {story.targetPage-2}
                                    </div>
                                ) : ""
                            } 
                            { 
                                story.targetPage-1 > 0 ? (
                                    <div onClick={()=>{
                                        story.targetPage = story.targetPage-1
                                        setLoad(true)
                                        story.GetStory(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {story.targetPage-1}
                                    </div>
                                ) : "" 
                            }
                            <div className="active">
                                {story.targetPage}
                            </div>
                            { 
                                story.targetPage+1 <= story.countPage ? (
                                    <div onClick={()=>{
                                        story.targetPage = story.targetPage+1
                                        setLoad(true)
                                        story.GetStory(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {story.targetPage+1}
                                    </div>
                                ) : ""
                            } 
                            { 
                                story.targetPage+2 <= story.countPage ? (
                                    <div onClick={()=>{
                                        story.targetPage = story.targetPage+2
                                        setLoad(true)
                                        story.GetStory(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {story.targetPage+2}
                                    </div>
                                ) : ""
                            } 
                            { 
                                story.targetPage+3 <= story.countPage ? (
                                    <div onClick={()=>{
                                        story.targetPage = story.countPage
                                        setLoad(true)
                                        story.GetStory(auth.user.userToken).then(function(response){
                                            setLoad(false)
                                        }).catch(function(error){
                                            alert("error")
                                        })
                                    }}>
                                        {story.countPage}
                                    </div>
                                ) : ""
                            } 
                        </div>
                    ) : ""
                }
            </div>
        </div>
    )
}