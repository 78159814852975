import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"

import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {   
    const auth = useContext(GlobalContext)  
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()
    const [formMsg, setFormMsg] = useState("") 

    const [form, setForm] = useState({ 
        userLogin: "",
        userPassword: "",
    })
 
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()
        setLoad(true)
        auth.user.login(form.userLogin, form.userPassword).then( 
            function (result) {
                console.log(result)
                if(result == "auth")
                    window.location.reload();
                    //navigate(`/`)

                setFormMsg(result)
                setForm({ 
                    userLogin: "",
                    userPassword: ""
                }) 
                setLoad(false)  
            }
        )    
    }

    return(
        <section className={"login form-section"}>
            <div className={"conteiner"}>
                <div className="login-content">
                    <div className="grid">
                        <div className="login-wrapper">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="login__bg grid">
                                        <img src={Userprofile} />
                                    </div>
                                </div>
                                <div className="col-md-6" style={{position: "relative"}}>
                                    {
                                        load ? (
                                            <img className="loader-icon" src={Loader} />
                                        ): ""
                                    }
                                    <form onSubmit={handleSubmit} style={
                                        load ? 
                                            {opacity: '0'}
                                        : 
                                            {}
                                    }>
                                        <h1 className="title">Авторизация</h1> 
                                        <label>
                                            <input type="text" placeholder="Эл. почта" required name="userLogin" value={form.userLogin} onChange={changeHandler} />
                                        </label>
                                        <label>
                                            <input type="password" placeholder="Пароль" required name="userPassword" value={form.userPassword} onChange={changeHandler} />
                                        </label>

                                        {
                                            formMsg != "" ? (
                                                <div className="form-msg">
                                                    {formMsg}
                                                </div>
                                            ):""
                                        }

                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>
                                                    <input type="submit" value="Войти" />
                                                </label>
                                            </div>
                                            <div className="col-md-6"> 
                                            </div>
                                        </div> 
                                    </form>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}