import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import Delete from "../image/delete.svg"
import Save from "../image/save.svg"
import {Rates} from "../classes/"
import { RateItem } from "../component/Rate/RateItem"
import { title } from "process"

export const RatesPage = () => {   
    const auth = useContext(GlobalContext)   
    const [rates, setRates] = useState(new Rates(auth.user))
    const [load, setLoad] = useState(true) 

    rates.Load().then(
        function(result){
            if(rates.list.length)
                setLoad(false)
        }
    )

    useEffect(()=>{
        console.log(rates.list)
    },[load])
     
    const [form, setForm] = useState({ 
        title: "",
        price: 0,
    })

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const CreateRate = () => {
        rates.Create(form.title, form.price).then(
            function(result){
                setForm({title: "", price: 0})
                if(result){
                    UpdateList()
                }
            }
        )
    }

    const UpdateList = () => {
        setLoad(true)
        rates.Load().then(
            function(result){
                if(rates.list.length)
                    setLoad(false)
            }
        )
    }


    return(
        <div className="item-wrapper">
            <h2>Список тарифов</h2>
            
            <div className="mp-orders">
                <div className="mp-orders__header">
                    <div className="row">
                        <div className="col-md-2">
                            id
                        </div>
                        <div className="col-md-4">
                            Название топлива
                        </div>
                        <div className="col-md-3">
                            Цена за 1л.
                        </div>
                        <div className="col-md-3"> 
                        </div>
                    </div>
                </div>
                <div className="mp-orders__list">
                    <div className="mp-orders__item">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="grid">
                                    <div>Создать</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="grid">
                                    <div>
                                        <input type="text" placeholder="Новое название" value={form.title} name="title"  onChange={changeHandler} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="grid">
                                    <div>
                                        <input type="text" placeholder="Новая цена" value={form.price} name="price"  onChange={changeHandler} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"> 
                                <div className="btn-line"> 
                                    <button className="save" style={{backgroundImage: `url(${Save})`}} onClick={()=>{
                                        CreateRate()
                                    }}></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        load ? "":(  
                            rates.list.map(item=>{
                                return (
                                    <RateItem item={item} saveData={item.SaveData} DelData={item.DelData} UpdateList={UpdateList}  />
                                )
                            })  
                        )  
                    } 


                     
                </div>
            </div>
        </div>
    )
}