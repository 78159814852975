import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg" 
import Delete from "../../image/delete.svg"
import Save from "../../image/save.svg"
import { GlobalContext } from "../../context/GlobalContext" 

export const RateItem = (props: any) => {
    const auth = useContext(GlobalContext)   

    const [form, setForm] = useState({ 
        title: props.item.title,
        price: props.item.price,
    })

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const UpdateItem = () => {
        props.saveData({
            id: props.item.id,
            title: form.title,
            price: form.price
        }, auth.user.userToken).then(
            function(){
                props.UpdateList()
            }
        )
    }

    const DelItem = () => {
        props.DelData({
            id: props.item.id,
            title: form.title,
            price: form.price
        }, auth.user.userToken).then(
            function(){
                props.UpdateList()
            }
        )
    }
    
    return (
        <div className="mp-orders__item">
            <div className="row">
                <div className="col-md-2">
                    <div className="grid">
                        <div>#{props.item.id}</div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="grid">
                        <div>
                            <input type="text" value={form.title} name="title"  onChange={changeHandler} />
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="grid">
                        <div>
                            <input type="text" value={form.price} name="price"  onChange={changeHandler} />
                        </div>
                    </div>
                </div>
                <div className="col-md-3"> 
                    <div className="btn-line">
                        <button className="delete" onClick={()=>{DelItem()}} style={{backgroundImage: `url(${Delete})`}}></button>
                        <button className="save"   onClick={()=>{UpdateItem()}} style={{backgroundImage: `url(${Save})`}}></button>
                    </div>
                </div>
            </div>
        </div>
    )
}