import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import { SupplierItem } from "../classes"

export const TargetDeliverymanPage = () => {   
    const auth = useContext(GlobalContext)   
    const [load, setLoad] = useState(true) 
    const [customer, setCustomer] = useState(new SupplierItem(window.location.href)) 

    useEffect(()=>{
        customer.GetItem(auth.user.userToken).then(function(response){ 
            setLoad(false)
            console.log(response)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load){
        return(
            <div></div>
        )
    }

    return(
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="item-wrapper">
                        <h2>Данные клиента</h2>
                        <div className="user-data">
                            <p><strong>ФИО: </strong>{customer.item?.user.fio}</p> 
                            <p><strong>Номер авто: </strong>{customer.item?.user.carNumber}</p>
                            <p><strong>Марка авто: </strong>{customer.item?.user.carBrand}</p>
                            <p><strong>Номер телефона: </strong><a href={"tel:"+customer.item?.user.phone}>{customer.item?.user.phone}</a></p>
                            <p><strong>Email: </strong><a href={"mailto:"+customer.item?.user.user.email}>{customer.item?.user.user.email}</a></p>
                        </div>
                    </div>
                    <div className="item-wrapper">
                    <h2>Денежные переводы</h2>
                        <div className="mp-orders__header">
                            <div className="row">
                                <div className="col-md-2">
                                    id
                                </div>
                                <div className="col-md-7">
                                    Тип операции
                                </div> 
                                <div className="col-md-3">
                                    Дата/время
                                </div>
                            </div>
                        </div>

                        <div className="pay-list">
                            <div className="mp-orders__list"> 
                                {
                                    customer.item?.story ? (
                                        customer.item?.story.length ? (
                                            customer.item?.story.map(item=>{
                                                return (
                                                    <div className="mp-orders__item">
                                                        <div className="row">
                                                            <div className="col-md-2">
                                                                <div className="grid">
                                                                    <div>#{item.id}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-7">
                                                                <div className="grid">
                                                                    <div>
                                                                        <strong>{item.title}</strong><br/>
                                                                        {item.desc}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="grid">
                                                                    <div>{item.date}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                )
                                            })
                                        ):""
                                    ):""
                                } 
                            </div>
                        </div>

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="item-wrapper">

                        <h2>Заказы клиента</h2>
                        <div className="mp-orders__header">
                            <div className="row"> 
                                <div className="col-md-6">
                                    Клиент
                                </div>
                                <div className="col-md-6">
                                    Данные заказа
                                </div> 
                            </div>
                        </div>
                        <div className="mp-orders">
                            <div className="mp-orders__list">
                                {
                                    customer.item?.order ? (
                                        customer.item?.order.length ? (
                                            customer.item?.order.map(item=>{
                                                return (
                                                    <div className="mp-orders__item">
                                                        <div className="row"> 
                                                            <div className="col-md-6">
                                                                <div className="grid">
                                                                    <div className="mp-orders__image-block"> 
                                                                        <div className="mp-orders__data">
                                                                            <div className="grid">
                                                                                <div>
                                                                                    <b>ID: </b> #{item.customers.pk}<br/>
                                                                                    <b>ФИО: </b> {item.customers.fName} {item.customers.lName}<br/>
                                                                                    <b>Номер телефона: </b> <a href={"tel:"+item.customers.phone}>{item.customers.phone}</a><br/>
                                                                                    <b>Email: </b> <a href={"mailto:"+item.customers.user.email}>{item.customers.user.email}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="grid">
                                                                    <div>
                                                                        <b>ID Заказа: </b> #{item.pk}<br/>
                                                                        <b>Адрес доставки:</b> {item.address}<br/>
                                                                        <b>Вид топлива:</b> {item.rate.title} ({item.volume}л)<br/>
                                                                        <b>Цена заказа:</b> {item.finalPrice}<br/>
                                                                        <b>Статус:</b> {item.status}<br/>
                                                                        <b>Время доставки:</b> {item.time}<br/>
                                                                        <a href={"/orders/"+item.pk+"/"}><strong>Подробнее</strong></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : ""
                                    ) : ""
                                } 
                            </div>
                        </div>

                    </div>
                </div> 
            </div>
        </>
    )
} 