import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import { OrderList } from "../classes"

import Select, { AriaOnFocus } from 'react-select';

 

export const StatisticPage = () => {   
    const auth = useContext(GlobalContext)   
    const [orders, setOrders] = useState(new OrderList()) 
    const [load, setLoad] = useState(true) 
  
    const [customerLbl, setCustomerLbl] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);  
    const onMenuOpen = () => setIsMenuOpen(true);
    const onMenuClose = () => setIsMenuOpen(false);


    useEffect(()=>{
        orders.GetUsers(auth.user.userToken).then(function(response){
            orders.GetStatOrders(auth.user.userToken).then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
        }).catch(function(error){
            alert("error")
        })
    },[])


    const [selectedOption, setSelectedOption] = useState(null);
    const [form, setForm] = useState({ 
        minDate: "",
        maxDate: "",
        supplier:"",
        customer:"",
        rate:""
    })
 
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    


    if(load){
        return(
            <div></div>
        )
    }
    return( 
        <>

            <div className="item-wrapper">
                <h2>Статистика</h2>
                <div className="statistic-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="statistic-wrapper">
                                <p>
                                    <b>Всего заказов:</b> {orders.orderCount}
                                </p>
                                <p>
                                    <b>Общая стоимость заказов:</b> {orders.orderCost} руб
                                </p>
                                <p>
                                    <b>Сумма заработка:</b> {orders.progCount} руб
                                </p>
                                <p>
                                    <b>Процент доставщикам:</b> {orders.suppCount} руб
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>
                                        Дата от:
                                        <input type="date" placeholder="" name="minDate" value={form.minDate} onChange={changeHandler} />
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Дата до:
                                        <input type="date" placeholder="" name="maxDate" value={form.maxDate} onChange={changeHandler} />
                                    </label>
                                </div>

                                <div className="col-md-6">
                                    <label>
                                        Доставщик:
                                        <select 
                                            name="supplier" value={form.supplier}  
                                            onChange={(e) => { 
                                                setForm({ ...form, "supplier": e.target.value })
                                            }}  >
                                            <option value="">Все доставщики</option>
                                            {
                                                orders.supplier ? (
                                                    orders.supplier.length ? (
                                                        orders.supplier.map(item=>{
                                                            return (
                                                                <option value={item.pk}>#{item.pk}. {item.user.email}</option>
                                                            )
                                                        })
                                                    ):""
                                                ):""
                                            }
                                        </select>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Клиент: <span>{customerLbl}</span>
                                        <div style={{marginTop: "10px"}}>

                                        </div>
                                        <Select
                                            aria-labelledby="aria-label"
                                            inputId="aria-example-input"
                                            name="customer"
                                            onMenuOpen={onMenuOpen}
                                            onMenuClose={onMenuClose}
                                            onChange={(e)=>{
                                                setForm({ ...form, "customer": `${e!.value}` })
                                                setCustomerLbl(`${e!.label}`)
                                            }}
                                            options={
                                                orders.customer ? (
                                                    orders.customer.length ? (
                                                        orders.customer.map(item=>{
                                                            return { 
                                                                value: item.pk, 
                                                                label: item.user.email
                                                            }
                                                        })
                                                    ):[]
                                                ):[]
                                            }
                                        /> 
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label>
                                        Тип топлива:
                                        <select 
                                            name="rate" value={form.rate}  
                                            onChange={(e) => { 
                                                setForm({ ...form, "rate": e.target.value })
                                            }}  >
                                            <option value="">Все типы топлива</option>
                                            {
                                                orders.rate ? (
                                                    orders.rate.length ? (
                                                        orders.rate.map(item=>{
                                                            return (
                                                                <option value={item.pk}>#{item.pk}. {item.title}</option>
                                                            )
                                                        })
                                                    ):""
                                                ):""
                                            }
                                        </select>
                                    </label>
                                </div>

                                <div className="col-md-6">
                                    <label>
                                        <input 
                                            type="submit" 
                                            value="Найти" 
                                            style={{marginTop: "20px"}}
                                            onClick={()=>{
                                                setLoad(true)
                                                console.log(form)
                                                orders.GetStatOrders(auth.user.userToken, {
                                                    minDate: form.minDate,
                                                    maxDate: form.maxDate,
                                                    supplier: form.supplier,
                                                    customer: form.customer,
                                                    rate: form.rate
                                                }).then(function(response){
                                                    setLoad(false)
                                                }).catch(function(error){
                                                    alert("error")
                                                })
                                            }} 
                                        />
                                    </label>
                                </div>
                                <div className="col-md-6" style={{display: "none"}}>
                                    <label>
                                        <input type="submit" value="Выгрузить в excel" />
                                    </label>
                                </div>

                            </div>
                        </div>
 
                    </div>
                </div>
            </div>

            <div className="item-wrapper user-list">
                <div className="">
                    <div className="mp-orders__header">
                        <div className="row">
                            <div className="col-md-1">id</div>
                            <div className="col-md-3">Доставщик</div>
                            <div className="col-md-3">Клиент</div>
                            <div className="col-md-3">Данные заказа</div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                    <div className="mp-orders__list">
                        {
                            orders.list ? (
                                orders.list.length ? (
                                    orders.list.map(item => {
                                        return (
                                            <div className="mp-orders__item">
                                                <div className="row">
                                                    <div className="col-md-1">
                                                        <div className="grid">#{item.pk}</div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="grid">
                                                            <div className="mp-orders__image-block"> 
                                                                <div className="mp-orders__data">
                                                                    <div className="grid">
                                                                        <div>           
                                                                            {
                                                                                item.supplier ? (
                                                                                    <>
                                                                                        <b>id: </b> #{item.supplier.pk}<br/>
                                                                                        <b>ФИО: </b>{item.supplier.fio}<br/>
                                                                                        <b>Номер авто: </b>{item.supplier.carNumber}<br/>
                                                                                        <b>Номер телефона: </b> <a href={"tel:"+item.supplier.phone}>{item.supplier.phone}</a><br/>
                                                                                        <b>Email: </b> <a href={"mailto:"+item.supplier.user.email}>{item.supplier.user.email}</a>
                                                                                    </>
                                                                                ):""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="grid">
                                                            <div className="mp-orders__image-block"> 
                                                                <div className="mp-orders__data">
                                                                    <div className="grid">
                                                                        <div>
                                                                            <b>ID: </b> #{item.customers.pk}<br/>
                                                                            <b>ФИО: </b> {item.customers.fName} {item.customers.lName}<br/>
                                                                            <b>Номер телефона: </b> <a href={"tel:"+item.customers.phone}>{item.customers.phone}</a><br/>
                                                                            <b>Email: </b> <a href={"mailto:"+item.customers.user.email}>{item.customers.user.email}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="grid">
                                                            <div>
                                                                <b>Адрес доставки:</b> {item.address}<br/>
                                                                <b>Вид топлива:</b> {item.rate.title} ({item.volume}л)<br/>
                                                                <b>Статус:</b> {item.status}<br/>
                                                                <b>Время доставки:</b> {item.time}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="grid">
                                                            <div>
                                                                <a className="hover-btn" href={"/orders/"+item.pk+"/"}>
                                                                    Подробнее
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })    
                                ) : ""
                            ) : ""
                        } 
                    </div>
                </div>
            </div>
        </>

        
    )
}