import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import Delete from "../image/delete.svg"
import Save from "../image/save.svg"
import {Options} from "../classes/"
import { SettingItem } from "../component/Setting/SettingItem"

export const SettingPage = () => {   
    const auth = useContext(GlobalContext)  
    const [options, setOptions] = useState(new Options()) 
    const [load, setLoad] = useState(true) 

    options.Load(auth.user.userToken).then(
        function(result){
            if(options.list){
                setLoad(false) 
            }
        }
    )

    const SaveData = () => {
        options.SaveList(auth.user.userToken)
    }

    return(
        <div className="item-wrapper">
            <h2>Настройки</h2>
            <div className="row">
                {
                    load ? "":(  
                        options.list.map((item, index: number)=>{ 
                            return ( 
                                <SettingItem index={index} options={options} item={item} />
                            )
                        })  
                    )  
                } 
                <div className="col-md-12">
                    <input type="submit" value="Сохранить" onClick={SaveData} style={{width: "200px", maxWidth: "100%"}} />
                </div> 
            </div>
        </div>
    )
}

