import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import { GlobalContext } from "../context/GlobalContext" 

export const Header = (props: any) => {
    const auth = useContext(GlobalContext)   
    return (
        <div className="admin-header">
            <div className="admin-header__wrapper">
                <div className="admin-header__userpic">
                    <div className="flex">
                        <div className="image" style={{backgroundImage:`url(${PlaceholderPic})`}}></div>
                        <div className="info">
                            <div>
                                <h2>Admin</h2> 
                            </div>
                        </div>
                    </div>
                </div>
                <div className="admin-header__circle-btn exit" onClick={()=>{auth.user.logout()}}>
                    <svg fill="#000000" style={{width: "20px"}} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path d="M476.352,247.979l-85.333-74.667c-3.136-2.731-7.616-3.392-11.435-1.685c-3.819,1.728-6.251,5.525-6.251,9.707v32H256 c-5.888,0-10.667,4.779-10.667,10.667v64c0,5.888,4.779,10.667,10.667,10.667h117.333v32c0,4.181,2.453,7.979,6.251,9.707 c1.408,0.64,2.923,0.96,4.416,0.96c2.539,0,5.035-0.896,7.019-2.645l85.333-74.667c2.325-2.027,3.648-4.949,3.648-8.021 S478.677,250.005,476.352,247.979z"></path>
                        <path d="M341.333,320H256c-17.643,0-32-14.357-32-32v-64c0-17.643,14.357-32,32-32h85.333c5.888,0,10.667-4.779,10.667-10.667V32 c0-17.643-14.357-32-32-32H64C46.357,0,32,14.357,32,32v448c0,17.643,14.357,32,32,32h256c17.643,0,32-14.357,32-32V330.667 C352,324.779,347.221,320,341.333,320z"></path>
                    </svg>
                </div>


                <div className="admin-header__circle-btn open-menu" onClick={()=>{props.setOpenMenu(true)}}>
                    <svg viewBox="0 0 24 24"  style={{width: "20px"}} xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_429_11066)">
                            <path d="M3 6.00092H21M3 12.0009H21M3 18.0009H21" stroke="#292929" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_429_11066">
                                <rect width="24" height="24" fill="white" transform="translate(0 0.000915527)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    )
}