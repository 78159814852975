import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import { Dashbord } from "../classes"

export const DashbordPage = () => {   
    const auth = useContext(GlobalContext)   
    const [dashbord, setDashbord] = useState(new Dashbord()) 
    const [load, setLoad] = useState(true) 


    useEffect(()=>{
        dashbord.GetData(auth.user.userToken).then(function(response){  
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load){
        return(
            <div></div>
        )
    }

    return(
        <div className="row">
            <div className="col-md-6">
                <div className="row">
                    <div className="col-md-6">
                        <div className="item-wrapper icon-block">
                            <div className="item-icon" style={{backgroundColor: "#e7b200"}}>
                                <img src={Question} />
                            </div>
                            <h2>Запросы<br/>к поддержке</h2>
                            <div className="counter">{dashbord.support}</div> 
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="item-wrapper money-log">
                            <div className="item-icon" style={{backgroundColor: "#22cc62"}}>
                                <img src={Order} />
                            </div>
                            <h2>Заказов<br/>сегодны</h2>
                            <div className="counter">
                                {dashbord.today}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item-wrapper chart">
                    <h2>Заказы на этой неделе</h2>
                    <div className="chart-row">
                        <div className="row">
                            <div className="col">
                                <div className="chart-item">
                                    <div style={{height: 200 / dashbord.maxCount * dashbord.days.mo+"px",marginTop: 200 - (200 / dashbord.maxCount * dashbord.days.mo)+"px"}}></div>
                                </div>
                                <div className="chart-day">
                                    Пн<br/>
                                    {dashbord.days.mo}
                                </div>
                            </div>
                            <div className="col">
                                <div className="chart-item">
                                    <div style={{height: 200 / dashbord.maxCount * dashbord.days.tu+"px",marginTop: 200 - (200 / dashbord.maxCount * dashbord.days.tu)+"px"}}></div>
                                </div>
                                <div className="chart-day">
                                    Вт<br/>
                                    {dashbord.days.tu}
                                </div>
                            </div>
                            <div className="col">
                                <div className="chart-item">
                                    <div style={{height: 200 / dashbord.maxCount * dashbord.days.we+"px",marginTop: 200 - (200 / dashbord.maxCount * dashbord.days.we)+"px"}}></div>
                                </div>
                                <div className="chart-day">
                                    Ср<br/>
                                    {dashbord.days.we}
                                </div>
                            </div>
                            <div className="col">
                                <div className="chart-item">
                                    <div style={{height: 200 / dashbord.maxCount * dashbord.days.th+"px",marginTop: 200 - (200 / dashbord.maxCount * dashbord.days.th)+"px"}}></div>
                                </div>
                                <div className="chart-day">
                                    Чт<br/>
                                    {dashbord.days.th}
                                </div>
                            </div>
                            <div className="col">
                                <div className="chart-item">
                                    <div style={{height: 200 / dashbord.maxCount * dashbord.days.fr+"px",marginTop: 200 - (200 / dashbord.maxCount * dashbord.days.fr)+"px"}}></div>
                                </div>
                                <div className="chart-day">
                                    Пт<br/>
                                    {dashbord.days.fr}
                                </div>
                            </div>
                            <div className="col">
                                <div className="chart-item">
                                    <div style={{height: 200 / dashbord.maxCount * dashbord.days.sa+"px",marginTop: 200 - (200 / dashbord.maxCount * dashbord.days.sa)+"px"}}></div>
                                </div>
                                <div className="chart-day">
                                    Сб<br/>
                                    {dashbord.days.sa}
                                </div>
                            </div>
                            <div className="col">
                                <div className="chart-item">
                                    <div style={{height: 200 / dashbord.maxCount * dashbord.days.su+"px",marginTop: 200 - (200 / dashbord.maxCount * dashbord.days.su)+"px"}}></div>
                                </div>
                                <div className="chart-day">
                                    Вс<br/>
                                    {dashbord.days.su}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            <div className="col-md-6">
                <div className="item-wrapper">
                    <h2>   
                        Последние заказы
                        <span>
                            <a href="/orders/" style={{color: "black"}}>Все заказы</a>
                        </span>
                    </h2>
                    <div className="mp-orders">
                        <div className="mp-orders__header">
                            <div className="row">
                                <div className="col-md-6">
                                    Клиент
                                </div>
                                <div className="col-md-6">
                                    Данные заказа
                                </div> 
                            </div>
                        </div>
                        <div className="mp-orders__list">
                            {
                                dashbord.order ? (
                                    dashbord.order.length ? (
                                        dashbord.order.map(item => {
                                            return (
                                                <div className="mp-orders__item">
                                                        <div className="row"> 
                                                            <div className="col-md-6">
                                                                <div className="grid">
                                                                    <div className="mp-orders__image-block"> 
                                                                        <div className="mp-orders__data">
                                                                            <div className="grid">
                                                                                <div>
                                                                                    <b>ID: </b> #{item.customers.pk}<br/>
                                                                                    <b>ФИО: </b> {item.customers.fName} {item.customers.lName}<br/>
                                                                                    <b>Номер телефона: </b> <a href={"tel:"+item.customers.phone}>{item.customers.phone}</a><br/>
                                                                                    <b>Email: </b> <a href={"mailto:"+item.customers.user.email}>{item.customers.user.email}</a>                                                                
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="grid">
                                                                    <div>
                                                                        <b>ID Заказа: </b> #{item.pk}<br/>
                                                                        <b>Адрес доставки:</b> {item.address}<br/>
                                                                        <b>Вид топлива:</b> {item.rate.title} ({item.volume}л)<br/>
                                                                        <b>Цена заказа:</b> {item.finalPrice}<br/>
                                                                        <b>Статус:</b> {item.status}<br/>
                                                                        <b>Время доставки:</b> {item.time}<br/>
                                                                        <a href={"/orders/"+item.pk+"/"}><strong>Подробнее</strong></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )
                                        })
                                    ): ""
                                ): ""
                            }
                             
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}