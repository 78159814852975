import React, { ChangeEvent, useContext, useState, Component } from "react"
import {Route} from 'react-router-dom' 
import {LoginPage} from './pages/LoginPage'
import { GlobalContext } from "./context/GlobalContext"
import { DashbordPage } from "./pages/DashbordPage"
import { RatesPage } from "./pages/RatesPage"
import { SettingPage } from "./pages/SettingPage"
import { NewsPage } from "./pages/NewsPage"
import { DeliverymanNewPage } from "./pages/DeliverymanNewPage"
import { DeliverymanPage } from "./pages/DeliverymanPage"
import { CustomersPage } from "./pages/CustomersPage"
import { TargetCustomerPage } from "./pages/TargetCustomerPage"
import { TargetDeliverymanPage } from "./pages/TargetDeliverymanPage"
import { TicketsPage } from "./pages/TicketsPage"
import { OrdersPage } from "./pages/OrdersPage"
import { TargetOrderPage } from "./pages/TargetOrderPage"
import { MoneyLogPage } from "./pages/MoneyLogPage"
import { StatisticPage } from "./pages/StatisticPage"
import { StocksPage } from "./pages/StocksPage"
import { ProductsPage } from "./pages/ProductsPage"



export const useMyRoutes = () => { 
    const auth = useContext(GlobalContext)  
    return (
        <>
        {
            auth.user.isAuthenticated ? (
                <>
                <Route path={`/`} element={<DashbordPage/>}/>
                <Route path={`/rates/`} element={<RatesPage/>}/>

                <Route path={`/product/`} element={<ProductsPage/>}/>

                <Route path={`/setting/`} element={<SettingPage/>}/>
                
                <Route path={`/news/`} element={<NewsPage/>}/>
                <Route path={`/stocks/`} element={<StocksPage/>}/>

                <Route path={`/new-deliveryman/`} element={<DeliverymanNewPage/>}/>
                <Route path={`/customers/`} element={<CustomersPage/>}/>
                <Route path={`/customers/:targetID/`} element={<TargetCustomerPage/>}/>

                <Route path={`/tickets/`} element={<TicketsPage/>}/>

                <Route path={`/orders/`} element={<OrdersPage/>}/>
                <Route path={`/orders/:targetID/`} element={<TargetOrderPage/>}/>

                <Route path={`/moneylog/`} element={<MoneyLogPage/>}/>
                <Route path={`/statistic/`} element={<StatisticPage/>}/>

                
                
                <Route path={`/deliveryman/`} element={<DeliverymanPage/>}/>
                <Route path={`/deliveryman/:targetID/`} element={<TargetDeliverymanPage/>}/>
                
                </>
            ):(
                <Route path={`/`} element={<LoginPage/>}/>
            )           
        }
        </>
    ) 
}