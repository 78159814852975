import axios from 'axios'
import { error } from 'console'
import { type } from 'os'

const host = 'https://api.tpkdrive-app.ru/api/web/'

export interface DeliverymanType {
    fio: string
    carNumber: string
    carBrand: string
    phone: string
    email: string
    password: string
}

export interface OptionItemType {
    id: number
    key: string
    title: string
    description: string 
}

export interface RateItemType {
    id: number
    title: string
    price: number
}

export interface ProductItemType {
    id: number
    title: string
    price: number
}

export interface TicketsType {
    id: number
    title: string
    lastMsg: string
    lastMsgDate: string
}

export interface TicketsMsgType {
    date: string
    desc: string
    sender: string
}

export interface NewsType {
    id: number
    active: boolean
    title: string
    image: string
    minidesc: string
    desc: string
}

export interface StoryType {
    id: number
    date: string
    title: string
    desc: string
    user: number
}

export interface OrderListType {
    pk: number
    customers: {
        pk: number
        user: {
            pk: number
            email: string
        },
        fName: string
        lName: string
        inn: string
        bDate: string
        phone: string
    }
    supplier: {
        pk: number,
        user: {
            pk: number,
            email: string
        },
        fio: string,
        carNumber: string,
        carBrand: string,
        phone: string
    }
    price: number
    finalPrice: number
    rate: {
        pk: number
        title: string
    } 
    address: string
    volume: number
    status: string
    time: string
    review: number
    deferred: string
    isopen: string
}

export interface OrderItemType {
    pk: number 
    customers: {
        pk: number
        fName: string
        lName: string
        phone: string
    }
    deliveryPrice: number
    supplier: {
        pk: number
        fio: string
        carNumber: string
        carBrand: string
        phone: string
    }
    rate: {
        pk: number
        title: string
    }
    car: {
        pk: number
        title: string
        color: string
        number: string
        carType: string
    }
    price: number
    finalPrice: number
    address: string
    volume: number
    status: string
    time: string
    review: number
    deferred: string
    isopen: string
    latitude: number
    longitude: number
    comment: string

    product: [
        {
            name: string
            cost: number
            count: number
            finalCost: number
        }
    ]
}

export interface CarType {
    pk: number
    title: string
    color: string
    number: string
    carType: string
}

export interface CustomerItemType {
    user: {
        pk: number
        user: {
            pk: number
            email: string
        }
        userType: string
        fName: string
        lName: string
        inn: string
        bDate: string
        phone: string
        score: string
    }
    order: OrderListType[] 
    car: CarType[]
    story: StoryType[]
}

export interface SupplierItemType {
    user: {
        pk: number
        user: {
            pk: number
            email: string
        }
        fio: string
        carNumber: string
        carBrand: string
        phone: string
    },
    order: OrderListType[]  
    story: StoryType[]
}

export interface CustomersType {
    pk: number
    user: {
        pk: number
        email: string
    },
    fName: string
    lName: string
    phone: string
}

export interface DeliveryItemType {
    pk: number
    user: {
        pk: number
        email: string
    }
    fio: string
    carNumber: string
    carBrand: string
    phone: string
}

//===========================================================//
export class TicketItem{
    id: number
    title: string
    lastMsg: string
    lastMsgDate: string
    
    constructor(item: TicketsType){
        this.id = item.id
        this.title = item.title
        this.lastMsg = item.lastMsg
        this.lastMsgDate = item.lastMsgDate
    }
}

export class Tickets{
    list: TicketItem[]
    msg: TicketsMsgType[]
    constructor(){
        this.list = []
        this.msg = []
    } 

    async SendMsg(userID: string, itemId: number, desc: string){
        let data = true
        await axios.post(`${host}addmessage/`, {
            "desc": desc, 
            "itemId": itemId 
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+userID
            }
        }).then(res => { 
            data = true
        })
    }

    async GetTarget(userID: string, itemId: number){
        let data = true
        await axios.get(`${host}ticket/${itemId}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+userID
            }
        }).then(res => {  
            this.msg = []
            this.msg.shift()
            res.data.forEach((item: TicketsMsgType)=>{
                this.msg.push({...item}) 
            })
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }

    async GetList(userID: string){
        let data = true
        await axios.get(`${host}ticket/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+userID
            }
        }).then(res => { 
            console.log(res.data) 
            this.list = []
            this.list.shift(); // 1
            res.data.forEach((item: TicketItem)=>{
                this.list.push(new TicketItem({...item})) 
            })
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }
}

export class SupplierItem{
    item?: SupplierItemType
    id: number
    constructor(id: string){ 
        const subArray = id.split('/')
        this.id = Number(subArray[subArray.length-2])
    }

    async GetItem(token: string){
        let data
        await axios.get(`${host}supplier/${this.id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.item = res.data
            data = true
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }
}

export class OrderItem{
    itemID: number
    item?: OrderItemType

    constructor(id: string){
        const subArray = id.split('/')
        this.itemID = Number(subArray[subArray.length-2])
        console.log(this.itemID)
    }

    async GetItem(token: string){
        let data
        await axios.get(`${host}order/${this.itemID}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {
            console.log(res.data)
            this.item = res.data 
        }).catch(error => {
            data = false
        }) 
        return data
    }
}

export class OrderList{
    list: OrderListType[]
    targetPage: number = 1
    countPage: number = 0

    customer: {
        pk: number
        user: {
            pk: number
            email: string
        } 
    }[]
    supplier: {
        pk: number
        user: {
            pk: number
            email: string
        } 
    }[]
    rate: {
        pk: number,
        title: string
    }[]
    orderCount: number = 0
    orderCost: number = 0
    progCount: number = 0
    suppCount: number = 0

    constructor(){
        this.list = []
        this.customer = []
        this.supplier = []
        this.rate = []
    }

    async GetUsers(token: string){
        let data
        await axios.get(`${host}statuser/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => { 
            console.log(res.data)
            this.customer = res.data.customer
            this.supplier = res.data.supplier
            this.rate = res.data.rate

        }).catch(function(error){
            data = false
        })
        return data
    }

    async GetStatOrders(token: string, filter: {
        minDate:    string,
        maxDate:    string,
        supplier:   string,
        customer:   string,
        rate:       string
    } = {
        minDate: "",
        maxDate: "",
        supplier:"",
        customer:"",
        rate:    ""
    }){
        let data
        console.log(filter.supplier)
        await axios.get(`${host}statorder/`, {
            params: {
                minDate: filter.minDate,
                maxDate: filter.maxDate,
                supplier: filter.supplier,
                customer: filter.customer,
                rate: filter.rate
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.list = []
            res.data.order.forEach((item: OrderListType) => {  
                this.list.push({...item})
            }) 

            this.orderCount = this.list.length
            this.orderCost = res.data.finalCost
            this.progCount = res.data.progCount
            this.suppCount = res.data.suppCount
            console.log(res.data)
        })
        .catch(function (error) { 
            
            data = false
        }) 
        return data
    }

    async GetOrders(token: string){
        let data
        await axios.get(`${host}order/?page=${this.targetPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.list = []
            this.countPage = Math.ceil(res.data.pageCount / 20)
            console.log(this.countPage)
            res.data.content.forEach((item: OrderListType) => { 
                this.list.push({...item})
            }) 
            console.log(res.data)
        })
        .catch(function (error) { 
            
            data = false
        }) 
        return data
    }
}

//==============================
export class News{
    list: NewsType[]
    
    constructor(){
        this.list = []
    }

    async CreateItem(formData: FormData, token: string){ 

        axios.post("https://api.tpkdrive-app.ru/api/web/news/",formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then((res:any) => { 
            console.log(res.response)
            console.log(res)
        })
        .catch(function (error) { 
            console.log(error)
        })
    }

    async DelItem(token: string, id: number){
        let data
        await axios.delete(`${host}news/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            console.log(res.data)
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }

    async GetNews(token: string){
        let data
        await axios.get(`${host}news/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.list = []
            res.data.forEach((item: NewsType) => {
                this.list.push({...item})
            }) 
            console.log(res.data)
        })
        .catch(function (error) { 
            
            data = false
        }) 
        return data
    }

}   

export class Stocks{
    list: NewsType[]
    
    constructor(){
        this.list = []
    }

    async CreateItem(formData: FormData, token: string){ 

        axios.post("https://api.tpkdrive-app.ru/api/web/stocks/",formData,{
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+token
            }
        }).then((res:any) => { 
            console.log(res.response)
            console.log(res)
        })
        .catch(function (error) { 
            console.log(error)
        })
    }

    async DelItem(token: string, id: number){
        let data
        await axios.delete(`${host}stocks/${id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            console.log(res.data)
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }

    async GetNews(token: string){
        let data
        await axios.get(`${host}stocks/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.list = []
            res.data.forEach((item: NewsType) => {
                this.list.push({...item})
            }) 
            console.log(res.data)
        })
        .catch(function (error) { 
            
            data = false
        }) 
        return data
    }

}  
//==============================

export class Story{
    list: StoryType[]
    targetPage: number = 1
    countPage: number = 0
    constructor(){
        this.list = []
    }

    async GetStory(token: string){
        let data
        await axios.get(`${host}story/?page=${this.targetPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.list = []
            this.countPage = Math.ceil(res.data.pageCount / 20)
            console.log(this.countPage)
            res.data.content.forEach((item: StoryType) => {
                this.list.push({...item})
            }) 
            console.log(res.data)
        })
        .catch(function (error) { 
            
            data = false
        }) 
        return data
    }

}

export class CustomerItem{
    item?: CustomerItemType
    id: number
    constructor(id: string){ 
        const subArray = id.split('/')
        this.id = Number(subArray[subArray.length-2])
    }

    async GetItem(token: string){
        let data
        await axios.get(`${host}customer/${this.id}/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  

            console.log(res.data)
            this.item = res.data
            data = true
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }
}
 
export class Customers{
    list: CustomersType[]
    targetPage: number = 1
    countPage: number = 0

    constructor(){
        this.list = []
    }
    async GetList(token: string){
        let data
        await axios.get(`${host}customer/?page=${this.targetPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.list = []
            this.countPage = Math.ceil(res.data.pageCount / 20)
            res.data.content.forEach((item: any) => {
                this.list.push({...item})
            }) 
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }
}

export class Deliveryman{
    list: DeliveryItemType[]
    targetPage: number = 1
    countPage: number = 0

    constructor(){
        this.list = [] 
    }
    
    public static async CreateDeliveryman(data: {fio: string, carNumber: string, carBrand: string, phone: string, email: string, password: string}, userToken: string){
        let query = ""
        console.log(data)
        if(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(data.password)){ 
            await axios.post(`${host}supplier/`, {
                "fio": data.fio,
                "carNumber": data.carNumber,
                "carBrand": data.carBrand,
                "phone": data.phone,
                "email": data.email,
                "password": data.password,
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+userToken
                }
            }).then(res => { 
                query = "Пользователь успешно создан"
            })
            .catch(function (error) {  
                query = error.response.data.error
            })  
        }else{
            query = "Пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
        }
        return query
    }

    async GetList(token: string){
        let data
        await axios.get(`${host}supplier/?page=${this.targetPage}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.list = []
            this.countPage = Math.ceil(res.data.pageCount / 20)
            res.data.content.forEach((item: any) => {
                this.list.push({...item})
            }) 
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }

}

export class OptionItem implements OptionItemType{ 
    id: number
    key: string
    title: string
    description: string

    constructor(item: OptionItemType){
        this.id = item.id
        this.key = item.key
        this.title = item.title
        this.description = item.description
    }
 
}

export class Dashbord{
    order: OrderListType[]
    today = 0
    support = 0
    days = {
        mo: 0,
        tu: 0,
        we: 0,
        th: 0,
        fr: 0,
        sa: 0,
        su: 0
    }
    maxCount = 1
    constructor(){
        this.order = []
    }

    async GetData(token: string){
        let data
        await axios.get(`${host}dashbord/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {   
            this.order = res.data.order
            this.today = res.data.today
            this.support = res.data.support
            this.days = res.data.days 
  
            for (const key of Object.keys(this.days)) {
                if(this.days[key as keyof typeof this.days] > this.maxCount){
                    this.maxCount = this.days[key as keyof typeof this.days]
                }
                console.log(this.maxCount)
            }
        })
        .catch(function (error) { 
            console.log(error)
            data = false
        }) 
        return data
    }
}

export class Options{
    list: OptionItem[]

    constructor(){
        this.list = []
    }
    async Load(token: string){
        let data
        await axios.get(`${host}setting/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+token
            }
        }).then(res => {  
            this.list = []
            res.data.forEach((item: any) => {
                this.list.push({...item})
            }) 
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }
    SaveList(token: string){
        this.list.forEach(async item => {

            console.log(item.description)
            await axios.put(`${host}setting/${item.id}/`, {
                "id": item.id,
                "key": item.key,
                "title": item.title,
                "description": item.description
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token '+token
                }
            }).then(res => {  
                console.log("true")
            })
            .catch(function (error) { 
                console.log("false")
            }) 
        })
    }

}

export class RateItem implements RateItemType{
    id: number
    title: string
    price: number
    
    constructor(data: RateItemType){
        this.id = data.id
        this.title = data.title
        this.price = data.price
    }

    async SaveData(data: RateItemType, userToken: string){
        let query
        await axios.put(`${host}rate/${data.id}/`, {
            "id": data.id,
            "title": data.title,
            "price": data.price,
            "active_status": true
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+userToken
            }
        }).then(res => { 
            query = true
        })
        .catch(function (error) {  
            query = false
        }) 
        console.log(query)
    }

    async DelData(data: RateItemType, userToken: string){
        let query
        await axios.put(`${host}rate/${data.id}/`, {
            "id": data.id,
            "title": data.title,
            "price": data.price,
            "active_status": false
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+userToken
            }
        }).then(res => { 
            query = true
        })
        .catch(function (error) {  
            query = false
        }) 
        console.log(query)
    }
}

export class Rates {
    list: RateItem[]
    user: User
    constructor (user: User) {
        this.user = user
        this.list = []
    }

    async Create(title: string, price: number){
        let data
        await axios.post(`${host}rate/`, {
            "title": title,
            "price": price,
            "active_status": true
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+this.user.userToken
            }
        }).then(res => { 
            data = true
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }

    async Load(){
        let data
        await axios.get(`${host}rate/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+this.user.userToken
            }
        }).then(res => { 
            this.list = []
            res.data.forEach((item: RateItemType, index: number) => {
                this.list.push(new RateItem({...item}))
            }) 
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }
}

export class ProductItem implements ProductItemType{
    id: number
    title: string
    price: number
    
    constructor(data: ProductItemType){
        this.id = data.id
        this.title = data.title
        this.price = data.price
    }

    async SaveData(data: ProductItemType, userToken: string){
        let query
        await axios.put(`${host}product/${data.id}/`, {
            "id": data.id,
            "title": data.title,
            "price": data.price,
            "active_status": true
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+userToken
            }
        }).then(res => { 
            query = true
        })
        .catch(function (error) {  
            query = false
        }) 
        console.log(query)
    }

    async DelData(data: ProductItemType, userToken: string){
        let query
        await axios.put(`${host}product/${data.id}/`, {
            "id": data.id,
            "title": data.title,
            "price": data.price,
            "active_status": false
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+userToken
            }
        }).then(res => { 
            query = true
        })
        .catch(function (error) {  
            query = false
        }) 
        console.log(query)
    }
}

export class Product {
    list: ProductItem[]
    user: User
    constructor (user: User) {
        this.user = user
        this.list = []
    }

    async Create(title: string, price: number){
        let data
        await axios.post(`${host}product/`, {
            "title": title,
            "price": price,
            "active_status": true
        },{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+this.user.userToken
            }
        }).then(res => { 
            data = true
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }

    async Load(){
        let data
        await axios.get(`${host}product/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+this.user.userToken
            }
        }).then(res => { 
            this.list = []
            res.data.forEach((item: ProductItemType, index: number) => {
                this.list.push(new ProductItem({...item}))
            }) 
        })
        .catch(function (error) { 
            data = false
        }) 
        return data
    }
}



export class User {
    isAuthenticated: boolean = false
    userToken: string = ""

    constructor () { 
        if(localStorage.getItem("userStorage")){
            const userStorage = JSON.parse(localStorage.getItem("userStorage") || "")
            this.isAuthenticated = true
            this.userToken = userStorage.userToken
        }
    }

    logout(){
        localStorage.removeItem("userStorage")
        this.isAuthenticated = false
        this.userToken = "" 
    }

    async login(userLogin: string, userPassword: string){
        let data = ""
        if(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/.test(userPassword)){
            await axios.post(`${host}login/`, {
                email: userLogin,
                password: userPassword
            }).then(res => { 

                if(res.data.error == true){
                    data = res.data.msg
                
                }else{
                    this.isAuthenticated = true
                    this.userToken = res.data.msg.userToken

                    localStorage.setItem("userStorage", JSON.stringify({ 
                        isAuthenticated: true,
                        userToken: res.data.msg.userToken,
                    }))
                    data = "auth"
                }

            })
            .catch(function (error) { 
                data = ""+error
            }) 
        }else{
            data = "Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре"
        }
        return data
    }
}