import { useLocation } from "react-router-dom"
import Logo from "../image/logo.png" 
export const Sidebar = (props: any) => {
    const location = useLocation() 
    return (
        <div className={"admin-menu"+(props.openMenu ? " active":"")}>
            <div>
                <div className="admin-menu__logo center">
                    <div> 
                        <a href="/">
                            <img className="logo" src={Logo} /> 
                        </a>
                    </div>
                    <div className="admin-menu__close" onClick={()=>{props.setOpenMenu(false)}}> 
                        <svg style={{width: "20px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_429_11083)">
                                <path d="M7 7.00006L17 17.0001M7 17.0001L17 7.00006" stroke="#292929" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_429_11083">
                                    <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
                <div className="admin-menu__wrapper">
                    <ul>
                        <li className={location.pathname == "/tickets/" ? "active":""}>
                            <a href="/tickets/">
                                <svg style={{width: "20px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 18.75H5C4.53668 18.7474 4.09309 18.5622 3.76546 18.2345C3.43784 17.9069 3.25263 17.4633 3.25 17V14.5C3.25259 14.3019 3.33244 14.1126 3.47253 13.9725C3.61263 13.8324 3.80189 13.7526 4 13.75C4.46413 13.75 4.90925 13.5656 5.23744 13.2374C5.56563 12.9092 5.75 12.4641 5.75 12C5.75 11.5359 5.56563 11.0908 5.23744 10.7626C4.90925 10.4344 4.46413 10.25 4 10.25C3.80189 10.2474 3.61263 10.1676 3.47253 10.0275C3.33244 9.88737 3.25259 9.69811 3.25 9.5V7C3.25263 6.53668 3.43784 6.09309 3.76546 5.76546C4.09309 5.43784 4.53668 5.25263 5 5.25H19C19.4633 5.25263 19.9069 5.43784 20.2345 5.76546C20.5622 6.09309 20.7474 6.53668 20.75 7V9.5C20.7474 9.69811 20.6676 9.88737 20.5275 10.0275C20.3874 10.1676 20.1981 10.2474 20 10.25C19.5359 10.25 19.0908 10.4344 18.7626 10.7626C18.4344 11.0908 18.25 11.5359 18.25 12C18.25 12.4641 18.4344 12.9092 18.7626 13.2374C19.0908 13.5656 19.5359 13.75 20 13.75C20.1981 13.7526 20.3874 13.8324 20.5275 13.9725C20.6676 14.1126 20.7474 14.3019 20.75 14.5V17C20.7474 17.4633 20.5622 17.9069 20.2345 18.2345C19.9069 18.5622 19.4633 18.7474 19 18.75ZM4.75 15.16V17C4.75 17.0663 4.77634 17.1299 4.82322 17.1768C4.87011 17.2237 4.9337 17.25 5 17.25H19C19.0663 17.25 19.1299 17.2237 19.1768 17.1768C19.2237 17.1299 19.25 17.0663 19.25 17V15.16C18.5403 14.9894 17.9088 14.5849 17.457 14.0117C17.0052 13.4385 16.7595 12.7299 16.7595 12C16.7595 11.2701 17.0052 10.5615 17.457 9.98829C17.9088 9.41505 18.5403 9.01057 19.25 8.84V7C19.25 6.9337 19.2237 6.87011 19.1768 6.82322C19.1299 6.77634 19.0663 6.75 19 6.75H5C4.9337 6.75 4.87011 6.77634 4.82322 6.82322C4.77634 6.87011 4.75 6.9337 4.75 7V8.84C5.45965 9.01057 6.09123 9.41505 6.54301 9.98829C6.99479 10.5615 7.24046 11.2701 7.24046 12C7.24046 12.7299 6.99479 13.4385 6.54301 14.0117C6.09123 14.5849 5.45965 14.9894 4.75 15.16Z" />
                                </svg>
                                <span>Запросы от клиентов</span>
                            </a>
                        </li>
                        <li className={location.pathname == "/rates/" ? "active":""}>
                            <a href="/rates/">
                                <svg fill="#000000"style={{width: "20px"}} viewBox="0 0 52 52" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="M25,2.3l11.7.2a5.51,5.51,0,0,1,2.5,1h0l4.6,4.6,4.6,4.6a3.55,3.55,0,0,1,1,2.5h0L49.7,27a3.55,3.55,0,0,1-1,2.5h0L29.5,48.7a3.69,3.69,0,0,1-5,0h0L13.9,38.1,3.3,27.5a3.69,3.69,0,0,1,0-5h0L22.5,3.3a3.55,3.55,0,0,1,2.5-1ZM18.6,21l-1.9,1.8a.85.85,0,0,0,0,1.3L27.9,35.3a.85.85,0,0,0,1.3,0l1.9-1.9a.85.85,0,0,0,0-1.3L19.7,21A.65.65,0,0,0,18.6,21Zm5.3-5.4L22,17.5a.85.85,0,0,0,0,1.3L33.2,30a.85.85,0,0,0,1.3,0l1.9-1.9a.85.85,0,0,0,0-1.3L25.1,15.6A.91.91,0,0,0,23.9,15.6ZM42,10a3.69,3.69,0,0,0-5,0,3.69,3.69,0,0,0,0,5,3.69,3.69,0,0,0,5,0A3.69,3.69,0,0,0,42,10Z"/>
                                    </g>
                                </svg>
                                <span>Тарифы</span>
                            </a>
                        </li>
                        <li className={location.pathname == "/product/" ? "active":""}>
                            <a href="/product/">
                                <svg fill="#000000"style={{width: "20px"}} viewBox="0 0 52 52" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
                                    <g>
                                        <path d="M25,2.3l11.7.2a5.51,5.51,0,0,1,2.5,1h0l4.6,4.6,4.6,4.6a3.55,3.55,0,0,1,1,2.5h0L49.7,27a3.55,3.55,0,0,1-1,2.5h0L29.5,48.7a3.69,3.69,0,0,1-5,0h0L13.9,38.1,3.3,27.5a3.69,3.69,0,0,1,0-5h0L22.5,3.3a3.55,3.55,0,0,1,2.5-1ZM18.6,21l-1.9,1.8a.85.85,0,0,0,0,1.3L27.9,35.3a.85.85,0,0,0,1.3,0l1.9-1.9a.85.85,0,0,0,0-1.3L19.7,21A.65.65,0,0,0,18.6,21Zm5.3-5.4L22,17.5a.85.85,0,0,0,0,1.3L33.2,30a.85.85,0,0,0,1.3,0l1.9-1.9a.85.85,0,0,0,0-1.3L25.1,15.6A.91.91,0,0,0,23.9,15.6ZM42,10a3.69,3.69,0,0,0-5,0,3.69,3.69,0,0,0,0,5,3.69,3.69,0,0,0,5,0A3.69,3.69,0,0,0,42,10Z"/>
                                    </g>
                                </svg>
                                <span>Товары</span>
                            </a>
                        </li>
                        <li className={location.pathname == "/setting/" ? "active":""}>
                            <a href="/setting/">
                                <svg style={{width: "20px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.1 9.2214C18.29 9.2214 17.55 7.9414 18.45 6.3714C18.97 5.4614 18.66 4.3014 17.75 3.7814L16.02 2.7914C15.23 2.3214 14.21 2.6014 13.74 3.3914L13.63 3.5814C12.73 5.1514 11.25 5.1514 10.34 3.5814L10.23 3.3914C9.78 2.6014 8.76 2.3214 7.97 2.7914L6.24 3.7814C5.33 4.3014 5.02 5.4714 5.54 6.3814C6.45 7.9414 5.71 9.2214 3.9 9.2214C2.86 9.2214 2 10.0714 2 11.1214V12.8814C2 13.9214 2.85 14.7814 3.9 14.7814C5.71 14.7814 6.45 16.0614 5.54 17.6314C5.02 18.5414 5.33 19.7014 6.24 20.2214L7.97 21.2114C8.76 21.6814 9.78 21.4014 10.25 20.6114L10.36 20.4214C11.26 18.8514 12.74 18.8514 13.65 20.4214L13.76 20.6114C14.23 21.4014 15.25 21.6814 16.04 21.2114L17.77 20.2214C18.68 19.7014 18.99 18.5314 18.47 17.6314C17.56 16.0614 18.3 14.7814 20.11 14.7814C21.15 14.7814 22.01 13.9314 22.01 12.8814V11.1214C22 10.0814 21.15 9.2214 20.1 9.2214ZM12 15.2514C10.21 15.2514 8.75 13.7914 8.75 12.0014C8.75 10.2114 10.21 8.7514 12 8.7514C13.79 8.7514 15.25 10.2114 15.25 12.0014C15.25 13.7914 13.79 15.2514 12 15.2514Z"></path>
                                </svg>
                                <span>Настройки</span>
                            </a>
                        </li> 
                        <li className={location.pathname == "/news/" ? "active":""}>
                            <a href="/news/">
                                <svg version="1.1" style={{width: "20px"}} id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"> 
                                    <path d="M22,6v18c0,1.6,1.3,3,3,3h0c1.6,0,3-1.3,3-3v-9l-6,0"/>
                                    <path d="M22,6v18c0,1.3,0.8,2.4,2,2.8V27H8H7c-1.7,0-3-1.4-3-3V6H22z"/>
                                    <line x1="8" y1="11" x2="14" y2="11"/>
                                    <line x1="8" y1="15" x2="10" y2="15"/>
                                </svg>
                                <span>Новости</span>
                            </a>
                        </li> 
                        <li className={location.pathname == "/stocks/" ? "active":""}>
                            <a href="/stocks/">
                                <svg version="1.1" style={{width: "20px"}} id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"> 
                                    <path d="M22,6v18c0,1.6,1.3,3,3,3h0c1.6,0,3-1.3,3-3v-9l-6,0"/>
                                    <path d="M22,6v18c0,1.3,0.8,2.4,2,2.8V27H8H7c-1.7,0-3-1.4-3-3V6H22z"/>
                                    <line x1="8" y1="11" x2="14" y2="11"/>
                                    <line x1="8" y1="15" x2="10" y2="15"/>
                                </svg>
                                <span>Акции</span>
                            </a>
                        </li> 
                        <li className={location.pathname == "/new-deliveryman/" ? "active":""}>
                            <a href="/new-deliveryman/">
                                <svg style={{width: "20px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7 8C7 6.34315 8.34315 5 10 5C11.6569 5 13 6.34315 13 8C13 9.65685 11.6569 11 10 11C8.34315 11 7 9.65685 7 8ZM13.506 11.5648C14.4282 10.6577 15 9.39565 15 8C15 5.23858 12.7614 3 10 3C7.23858 3 5 5.23858 5 8C5 9.39827 5.57396 10.6625 6.49914 11.5699C3.74942 12.5366 2 14.6259 2 17C2 17.5523 2.44772 18 3 18C3.55228 18 4 17.5523 4 17C4 15.2701 5.93073 13 10 13C11.9542 13 13.4554 13.5451 14.4579 14.2992C14.8992 14.6311 15.5262 14.5425 15.8581 14.1011C16.1901 13.6598 16.1015 13.0328 15.6601 12.7008C15.0431 12.2368 14.3206 11.851 13.506 11.5648ZM19 14.5C19.5523 14.5 20 14.9477 20 15.5V18H22.5C23.0523 18 23.5 18.4477 23.5 19C23.5 19.5523 23.0523 20 22.5 20H20V22.5C20 23.0523 19.5523 23.5 19 23.5C18.4477 23.5 18 23.0523 18 22.5V20H15.5C14.9477 20 14.5 19.5523 14.5 19C14.5 18.4477 14.9477 18 15.5 18H18V15.5C18 14.9477 18.4477 14.5 19 14.5Z"/>
                                </svg>
                                <span>Создать доставщика</span>
                            </a>
                        </li>  
                        <li className={location.pathname == "/customers/" ? "active":""}>
                            <a href="/customers/">
                                <svg viewBox="0 -1 24 24" style={{width: "20px"}} id="meteor-icon-kit__solid-users" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99978 -0.000028C11.3134 -0.000028 13.9996 2.68619 13.9996 5.9998C13.9996 9.3134 11.3134 11.9996 7.99978 11.9996C4.68616 11.9996 1.99994 9.3134 1.99994 5.9998C1.99994 2.68619 4.68616 -0.000028 7.99978 -0.000028zM0.999972 22.0984C0.447703 22.0984 0 21.6507 0 21.0984V18.9994C0 16.2381 2.23851 13.9996 4.99986 13.9996H11.0004C13.7617 13.9996 16.0003 16.2381 16.0003 18.9994V21.0984C16.0003 21.6507 15.5526 22.0984 15.0003 22.0984C14.448 22.0984 1.55224 22.0984 0.999972 22.0984zM13.7484 11.563C15.142 10.1233 15.9996 8.16172 15.9996 5.9998C15.9996 3.83789 15.142 1.87627 13.7484 0.43658C14.4436 0.15502 15.2034 -0.000028 15.9996 -0.000028C19.3132 -0.000028 21.9994 2.68619 21.9994 5.9998C21.9994 9.3134 19.3132 11.9996 15.9996 11.9996C15.2034 11.9996 14.4436 11.8446 13.7484 11.563zM15.8992 13.9996H19.0002C21.7615 13.9996 24 16.2381 24 18.9994V21.0984C24 21.6507 23.5523 22.0984 23.0001 22.0984H17.8295C17.94 21.7856 18.0002 21.449 18.0002 21.0984V18.9994C18.0002 17.0408 17.1958 15.2701 15.8992 13.9996z"></path>
                                </svg>
                                <span>Клиенты</span>
                            </a>
                        </li>
                        <li className={location.pathname == "/deliveryman/" ? "active":""}>
                            <a href="/deliveryman/">
                                <svg style={{width: "20px"}} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="invisible_box" data-name="invisible box">
                                            <rect width="48" height="48" fill="none"/>
                                        </g>
                                        <g id="Health_Icons" data-name="Health Icons">
                                            <g>
                                                <path d="M37.7,11.1A3,3,0,0,0,35.4,10H34.2l.3-1.7A3.1,3.1,0,0,0,33.9,6a3.2,3.2,0,0,0-2.2-1H7.8a2,2,0,0,0,0,4H30.3l-4,22.9a6.8,6.8,0,0,0-1,2.1H20.7A7,7,0,0,0,7.3,34H6.2l.5-2.9a2,2,0,0,0-1.6-2.3,2,2,0,0,0-2.3,1.6L2,34.7A2.8,2.8,0,0,0,2.7,37a2.8,2.8,0,0,0,2.1,1H7.3a7,7,0,0,0,13.4,0h4.6a7,7,0,0,0,13.4,0h2a3.2,3.2,0,0,0,3.1-2.7L46,22.5ZM14,39a3,3,0,0,1-3-3,3,3,0,0,1,6,0A3,3,0,0,1,14,39ZM33.5,14h1.3l5.9,8H32.1ZM32,39a3,3,0,0,1-3-3,3,3,0,0,1,6,0A3,3,0,0,1,32,39Zm8-5H38.7A7,7,0,0,0,32,29H30.9l.5-3.1h9.9Z"/>
                                                <path d="M4,15H14a2,2,0,0,0,0-4H4a2,2,0,0,0,0,4Z"/>
                                                <path d="M15,19a2,2,0,0,0-2-2H5a2,2,0,0,0,0,4h8A2,2,0,0,0,15,19Z"/>
                                                <path d="M6,23a2,2,0,0,0,0,4h6a2,2,0,0,0,0-4Z"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                <span>Доставщики</span>
                            </a>
                        </li>
                        <li className={location.pathname == "/orders/" ? "active":""}>
                            <a href="/orders/">
                                <svg version="1.1" style={{width: "20px"}} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
                                    <path className="st0" d="M16.5,3C20,3,22,5,22,8.4l0,0h-4.2v0c-2,0-3.6,1.6-3.6,3.5s1.6,3.5,3.6,3.5l0,0H22v0.3c0,3.4-2,5.3-5.5,5.3l0,0 h-9C4,21,2,19,2,15.7l0,0V8.3C2,5,4,3,7.5,3l0,0H16.5z M21.3,9.9c0.4,0,0.7,0.3,0.7,0.7l0,0v2.5c0,0.4-0.3,0.7-0.7,0.7l0,0h-3.4 c-1,0-1.9-0.7-2.1-1.6c-0.1-0.6,0-1.2,0.4-1.7c0.4-0.5,1-0.7,1.6-0.7l0,0H21.3z"></path>
                                </svg>   
                                <span>Заказы</span>
                            </a>
                        </li>
                        <li className={location.pathname == "/moneylog/" ? "active":""}>
                            <a href="/moneylog/">
                                <svg style={{width: "20px"}}viewBox="0 -0.5 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Page-1" stroke="none" stroke-width="1"  fill-rule="evenodd">
                                        <g id="Dribbble-Light-Preview" transform="translate(-260.000000, -2759.000000)">
                                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                                <path d="M222,2612.40709 C222,2612.84565 221.729,2613.23594 221.324,2613.3787 L215,2615.60539 L215,2603.71501 L215,2603.53322 L220.676,2601.53454 C221.325,2601.3055 222,2601.80055 222,2602.50615 L222,2612.40709 Z M213,2603.71501 L213,2615.60539 L206.676,2613.3787 C206.271,2613.23594 206,2612.84565 206,2612.40709 L206,2602.50615 C206,2601.80055 206.675,2601.3055 207.324,2601.53454 L213,2603.53322 L213,2603.71501 Z M221.337,2599.11785 L214.331,2601.64444 C214.117,2601.72147 213.883,2601.72147 213.669,2601.64444 L206.663,2599.11785 C205.362,2598.64847 204,2599.6396 204,2601.05592 L204,2613.11577 C204,2613.997 204.547,2614.78065 205.36,2615.06207 L213.68,2617.94608 C213.888,2618.01797 214.112,2618.01797 214.32,2617.94608 L222.64,2615.06207 C223.453,2614.78065 224,2613.997 224,2613.11577 L224,2601.05592 C224,2599.6396 222.638,2598.64847 221.337,2599.11785 L221.337,2599.11785 Z" id="book-[#1207]"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg> 
                                <span>История денежных операций</span>
                            </a>
                        </li>
                        <li className={location.pathname == "/statistic/" ? "active":""}>
                            <a href="/statistic/">
                                <svg style={{width: "20px", transform: "transform: scale(-1, 1)"}} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.466 15.466">
                                    <g>
                                        <g>
                                            <path d="M3.566,4.1H0.69C0.309,4.1,0,4.409,0,4.79v9.24c0,0.381,0.309,0.69,0.69,0.69h2.876 c0.381,0,0.69-0.31,0.69-0.69V4.79C4.257,4.409,3.947,4.1,3.566,4.1z"></path>
                                            <path d="M9.203,7.379H6.327c-0.381,0-0.691,0.309-0.691,0.69v5.961c0,0.381,0.31,0.69,0.691,0.69h2.876 c0.382,0,0.69-0.31,0.69-0.69V8.069C9.893,7.688,9.585,7.379,9.203,7.379z"></path>
                                            <path d="M14.775,9.967h-2.876c-0.382,0-0.689,0.31-0.689,0.69v3.373c0,0.381,0.308,0.69,0.689,0.69h2.876 c0.382,0,0.691-0.31,0.691-0.69v-3.373C15.466,10.277,15.157,9.967,14.775,9.967z"></path>
                                        </g>
                                    </g>
                                </svg>
                                <span>Статистика</span>
                            </a>
                        </li> 
                    </ul>
                </div>
                <div className="admin-menu__underline">
                    Copyright © 2023. tpk-drive.ru
                </div>
            </div>
        </div>
    )
}