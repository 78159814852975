import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import { OrderList } from "../classes"

export const OrdersPage = () => {   
    const auth = useContext(GlobalContext)   
    const [orders, setOrders] = useState(new OrderList()) 
    const [load, setLoad] = useState(true) 


    useEffect(()=>{
        orders.GetOrders(auth.user.userToken).then(function(response){ 
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load){
        return(
            <div></div>
        )
    }


    return( 
        <div className="item-wrapper user-list">
            <div className="">
                <div className="mp-orders__header">
                    <div className="row">
                        <div className="col-md-1">id</div>
                        <div className="col-md-3">Доставщик</div>
                        <div className="col-md-3">Клиент</div>
                        <div className="col-md-3">Данные заказа</div>
                        <div className="col-md-2"></div>
                    </div>
                </div>
                <div className="mp-orders__list">
                    {
                        orders.list ? (
                            orders.list.length ? (
                                orders.list.map(item => {
                                    console.log(item)
                                    return (
                                        <div className="mp-orders__item">
                                            <div className="row">
                                                <div className="col-md-1">
                                                    <div className="grid">#{item.pk}</div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="grid">
                                                        <div className="mp-orders__image-block"> 
                                                            <div className="mp-orders__data">
                                                                <div className="grid">
                                                                    <div>             
                                                                        {
                                                                            item.supplier ? (
                                                                                <>
                                                                                    <b>id: </b> #{item.supplier.pk}<br/>
                                                                                    <b>ФИО: </b>{item.supplier.fio}<br/>
                                                                                    <b>Номер авто: </b>{item.supplier.carNumber}<br/>
                                                                                    <b>Номер телефона: </b> <a href={"tel:"+item.supplier.phone}>{item.supplier.phone}</a><br/>
                                                                                    <b>Email: </b> <a href={"mailto:"+item.supplier.user.email}>{item.supplier.user.email}</a>
                                                                                </>
                                                                            ):""
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="grid">
                                                        <div className="mp-orders__image-block"> 
                                                            <div className="mp-orders__data">
                                                                <div className="grid">
                                                                    <div>
                                                                        <b>ID: </b> #{item.customers.pk}<br/>
                                                                        <b>ФИО: </b> {item.customers.fName} {item.customers.lName}<br/>
                                                                        <b>Номер телефона: </b> <a href={"tel:"+item.customers.phone}>{item.customers.phone}</a><br/>
                                                                        <b>Email: </b> <a href={"mailto:"+item.customers.user.email}>{item.customers.user.email}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="grid">
                                                        <div>
                                                            <b>Адрес доставки:</b> {item.address}<br/>
                                                            <b>Вид топлива:</b> {item.rate.title} ({item.volume}л)<br/>
                                                            <b>Статус:</b> {item.status}<br/>
                                                            {
                                                                item.review != 0 ? (
                                                                    <>
                                                                        <b>Оценка:</b> {item.review}<br/>
                                                                    </>
                                                                ):""
                                                            }
                                                            <b>Время доставки:</b> {item.time}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <div className="grid">
                                                        <div>
                                                            <a className="hover-btn" href={"/orders/"+item.pk+"/"}>
                                                                Подробнее
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })    
                            ) : ""
                        ) : ""
                    } 
                </div>

                <div className="pay-page">
                    {
                        orders.countPage != 0 ? (
                            <div>
                                { 
                                    orders.targetPage> 3 ? (
                                        <div onClick={()=>{
                                            orders.targetPage = 1
                                            setLoad(true)
                                            orders.GetOrders(auth.user.userToken).then(function(response){
                                                setLoad(false)
                                            }).catch(function(error){
                                                alert("error")
                                            })
                                        }}>
                                            1
                                        </div>
                                    ) : ""
                                } 
                                { 
                                    orders.targetPage-2 > 0 ? (
                                        <div onClick={()=>{
                                            orders.targetPage = orders.targetPage-2
                                            setLoad(true)
                                            orders.GetOrders(auth.user.userToken).then(function(response){
                                                setLoad(false)
                                            }).catch(function(error){
                                                alert("error")
                                            })
                                        }}>
                                            {orders.targetPage-2}
                                        </div>
                                    ) : ""
                                } 
                                { 
                                    orders.targetPage-1 > 0 ? (
                                        <div onClick={()=>{
                                            orders.targetPage = orders.targetPage-1
                                            setLoad(true)
                                            orders.GetOrders(auth.user.userToken).then(function(response){
                                                setLoad(false)
                                            }).catch(function(error){
                                                alert("error")
                                            })
                                        }}>
                                            {orders.targetPage-1}
                                        </div>
                                    ) : "" 
                                }
                                <div className="active">
                                    {orders.targetPage}
                                </div>
                                { 
                                    orders.targetPage+1 <= orders.countPage ? (
                                        <div onClick={()=>{
                                            orders.targetPage = orders.targetPage+1
                                            setLoad(true)
                                            orders.GetOrders(auth.user.userToken).then(function(response){
                                                setLoad(false)
                                            }).catch(function(error){
                                                alert("error")
                                            })
                                        }}>
                                            {orders.targetPage+1}
                                        </div>
                                    ) : ""
                                } 
                                { 
                                    orders.targetPage+2 <= orders.countPage ? (
                                        <div onClick={()=>{
                                            orders.targetPage = orders.targetPage+2
                                            setLoad(true)
                                            orders.GetOrders(auth.user.userToken).then(function(response){
                                                setLoad(false)
                                            }).catch(function(error){
                                                alert("error")
                                            })
                                        }}>
                                            {orders.targetPage+2}
                                        </div>
                                    ) : ""
                                } 
                                { 
                                    orders.targetPage+3 <= orders.countPage ? (
                                        <div onClick={()=>{
                                            orders.targetPage = orders.countPage
                                            setLoad(true)
                                            orders.GetOrders(auth.user.userToken).then(function(response){
                                                setLoad(false)
                                            }).catch(function(error){
                                                alert("error")
                                            })
                                        }}>
                                            {orders.countPage}
                                        </div>
                                    ) : ""
                                } 
                            </div>
                        ) : ""
                    }
                </div>
            </div>
        </div>
    )
}