import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom" 
import {Deliveryman} from "../classes/"
import Delete from "../image/delete.svg"
import Save from "../image/save.svg"

export const DeliverymanNewPage = () => {   
    const auth = useContext(GlobalContext)   
    const [answer, setAnswer] = useState("")

    const NewDelivery = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if(
            form.fio != "" &&
            form.carNumber != "" &&
            form.carBrand != "" &&
            form.phone != "" &&
            form.email != "" &&
            form.password != ""
        ){
            Deliveryman.CreateDeliveryman(form, auth.user.userToken).then(
                function(result){
                    setAnswer(result)
                }
            )
        }else{
            setAnswer("Не все поля заполнены")
        }
    }

    const [form, setForm] = useState({ 
        fio: "",
        carNumber: "",
        carBrand: "",
        phone: "",
        email: "",
        password: ""
    })

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    

    return(
        <div className="item-wrapper">
            <h2>Создать доставщика</h2>
            {
                answer != "" ? (
                    <p className="serverAnswer">{answer}</p>
                ):""
            }
            <form onSubmit={NewDelivery}> 
                <div className="row">
                    <div className="col-md-6">
                        <label>
                            ФИО
                            <input type="text" value={form.fio} name="fio" onChange={changeHandler} />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label>
                            Номер автомобиля
                            <input type="text" value={form.carNumber} name="carNumber" onChange={changeHandler} />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label>
                            Марка автомобиля
                            <input type="text" value={form.carBrand} name="carBrand" onChange={changeHandler} />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label>
                            Номер телефона
                            <input type="text" value={form.phone} name="phone" onChange={changeHandler} />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label>
                            E-mail
                            <input type="email" value={form.email} name="email" onChange={changeHandler} />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <label>
                            Пароль
                            <input type="password" value={form.password} name="password" onChange={changeHandler} />
                        </label>
                    </div> 
                    <div className="col-md-6">
                        <input type="submit" value="Создать" style={{width: "200px", maxWidth: "100%"}} />
                    </div> 
                </div>
            </form>
        </div>
    )
}

