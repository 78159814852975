import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import { News, Stocks } from "../classes"
import axios from "axios"


export const StocksPage = () => {  
    const auth = useContext(GlobalContext)   
    const [news, setNews] = useState(new Stocks()) 
    const [load, setLoad] = useState(true) 

    const [image, setImage] = useState(null)
    const [form, setForm] = useState({ 
        name: "",
        minidesc: "", 
        text: "", 
    })
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }
    function handlerImage(e: any){
        setImage(e.target.files[0])
        console.log(e.target.files)
    }
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        event.preventDefault()

        const formData = new FormData();
        formData.append("selectedFile", image!);
        formData.append("title", form.name)
        formData.append("image", image!)
        formData.append("minidesc", form.minidesc)
        formData.append("active", "true")
        formData.append("desc", form.text)
        
        setLoad(true)
        news.CreateItem(formData, auth.user.userToken).then(function(response){
            alert("Новость создана")
            
            news.GetNews(auth.user.userToken).then(function(response){
                setLoad(false)
            }).catch(function(error){
                alert("error")
            })
        }).catch(function(error){
            alert("error")
        })
    }
    

    useEffect(()=>{
        news.GetNews(auth.user.userToken).then(function(response){
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load){
        return(
            <div></div>
        )
    }

    return(
        <>
            <div className="item-wrapper">
                <h2>Создать уведомление</h2>

                <form onSubmit={handleSubmit} style={load ? {opacity: '0'} : {}}>
                    <label>
                        <input placeholder="Заголовок" required name="name" value={form.name} onChange={changeHandler} />
                    </label>
                    <label>
                        <input placeholder="Краткое описание" required name="minidesc" value={form.minidesc} onChange={changeHandler} />
                    </label>
                    <label>
                        <input placeholder="Текст сообщения" required name="text" value={form.text} onChange={changeHandler} />
                    </label>
                    <label style={{paddingLeft: "20px", fontSize: "14px", color: "rgba(0,0,0,0.4)"}}>
                        Фото новости
                        <input type="file" required name="file" onChange={handlerImage} />
                    </label>
                    <label>
                        <input type="submit" value="Создать" style={{width: "200px", maxWidth: "100%"}} />
                    </label>
                </form>
            </div>

            <div className="item-wrapper">
                <h2>Создать отзыв</h2>
                <div className="news-list">
                    {
                        news.list ? (
                            news.list.length ? (
                                news.list.map((item) => {
                                    return (
                                        <div className="news-item">
                                            <p className="news-title"><span>Заголовок:</span> {item.title}</p>
                                            <p className="news-text" style={{marginBottom: "5px"}}><span>Краткое описание:</span> {item.minidesc}</p>
                                            <p className="news-text"><span>Cообщение:</span> {item.desc}</p>
                                            <p className="del-news">
                                                <span onClick={async ()=>{
                                                    setLoad(true)
                                                    await news.DelItem(auth.user.userToken, item.id).catch(function(response){
                                                         
                                                    }).catch(function(error){
                                                        console.log("error")
                                                    })

                                                    news.GetNews(auth.user.userToken).then(function(response){
                                                        setLoad(false)
                                                    }).catch(function(error){
                                                        alert("error")
                                                    })
                                                }}>Удалить</span>
                                            </p>
                                        </div>
                                    )
                                })
                            ) : ""
                        ) : ""
                    }
                </div>
            </div>
        </>
    )
}