import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useNavigate } from "react-router-dom";
import {Tickets} from '../classes'

export const TicketsPage = () => {   
    const auth = useContext(GlobalContext) 

    const [tickets, setTickets] = useState(new Tickets())
    const [load, setLoad] = useState(true)
    const [targetId, setTargetId] = useState(0)

    const [form, setForm] = useState({ 
        msg: "",
    })
 
    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => { 
        setLoad(true)
        event.preventDefault() 
        if(targetId != 0){
            tickets.SendMsg(auth.user.userToken, targetId, form.msg).then(function(response){

                setForm({msg: ""})
                tickets.GetList(auth.user.userToken).then(function(response){
                    tickets.msg = []
                    setLoad(false)
                }).catch(function(error){
                    alert("error")
                })
                
            }).catch(function(error){
                console.log(error)
                alert("error")
            })
        }
    }

    useEffect(() => {
        tickets.GetList(auth.user.userToken).then(function(response){
            //alert(response)
            setLoad(false)
        }).catch(function(error){
            alert("error")
        })
    }, []);

    if(load){
        return (
            <div></div>
        )
    }
    return( 
        <div className="row">
            <div className="col-md-4">
                <div className="item-wrapper user-list">
                    <h2>Список запросов</h2>
                    <div className="tickets-list">
                        <div className="mp-orders__header">
                            <div className="row">
                                <div className="col-md-3">
                                    id
                                </div>
                                <div className="col-md-3">
                                    Тема
                                </div>
                                <div className="col-md-6">
                                    Последнее
                                </div>
                            </div>
                        </div>

                        <div className="mp-orders__list">
                            {
                                tickets.list ? (
                                    tickets.list.length ? (
                                        tickets.list.map(item=>{
                                            return (
                                                <div className="mp-orders__item" onClick={()=>{
                                                    setLoad(true)
                                                    setTargetId(item.id)
                                                    tickets.GetTarget(auth.user.userToken, item.id).then(function(response){
                                                        setLoad(false)
                                                        console.log(response)
                                                    }).catch(function(error){
                                                        alert("error")
                                                    })
                                                }}>
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="grid">
                                                                #{item.id}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="grid">
                                                                {item.title}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="grid">
                                                                {item.lastMsgDate}<br/>
                                                                <span>
                                                                    (Отправитель: {
                                                                        item.lastMsg == "customers" ? "Заказчик" : item.lastMsg == "supplier" ? "Поставщик" : "Админ"
                                                                    })
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    ) : ""
                                ):""
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <div className="item-wrapper user-list">
                    <div className="chat">
                        <div className="chat-header">
                            Сообщения запроса {targetId}
                        </div>
                        <div className="chat-body">
                            <div className="chat-msg-list">
                                {
                                    tickets.msg ? (
                                        tickets.msg.length ? (
                                            tickets.msg.map((item)=>{
                                                return (
                                                    <div className={`msg-item ${item.sender == "admin" ? " creater":" "}`} >
                                                        <div>{item.desc}</div>
                                                        <p>({item.sender == "customers" ? "Заказчик" : item.sender == "supplier" ? "Поставщик" : "Админ"}) {item.date}</p>
                                                    </div>
                                                )
                                            })  
                                        ):""
                                    ) : ""
                                } 
                            </div>
                            <div className="chat-new">
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <input type="text" placeholder="Новое сообщение" required name="msg" value={form.msg} onChange={changeHandler} />
                                        <input type="submit" value="Отправить" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}