import React, { useContext } from 'react'; 
import { Routes, Route } from "react-router-dom";
import { Layout } from "./layouts/layout" 
import {User} from './classes'
import { useMyRoutes } from "./routes"
import { GlobalContext } from "./context/GlobalContext"
import { Header } from './component/Header';
import { Sidebar } from './component/Sidebar';

function App() {
    const auth = useContext(GlobalContext)  
    const routes = useMyRoutes() 
  return (
    <GlobalContext.Provider value={{
        user: new User
    }}> 
        {
            auth.user.isAuthenticated ? (
                <div className="admin-wrapper">
                    <Sidebar/>
                    <div className="admin-content">
                        <Header/>
                        <div className="admin-content__mainspace">
                            <Routes>
                                <Route element={<Layout />}>{routes}</Route>
                            </Routes>
                        </div>
                    </div>
                </div>
            ):(
                <Routes>
                    <Route element={<Layout />}>{routes}</Route>
                </Routes>
            )
        }
    </GlobalContext.Provider>
  );
}

export default App;
