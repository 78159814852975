import React, { ChangeEvent, useContext, useState, Component, useCallback, useEffect } from "react"
import PlaceholderPic from "../image/placeholder-image.jpg"
import Question from "../image/question.svg"
import Order from "../image/order.svg"
import { GlobalContext } from "../context/GlobalContext" 
import Loader from '../image/loader.svg'
import Userprofile from '../image/userprofile.svg'
import { useLoaderData, useNavigate } from "react-router-dom";
import { OrderItem } from "../classes"
import { YMaps, Map, Placemark } from "react-yandex-maps";

export const TargetOrderPage = () => {   
    const auth = useContext(GlobalContext)   
    const [orders, setOrders] = useState(new OrderItem(window.location.href)) 
    const [load, setLoad] = useState(true) 
    

    useEffect(()=>{
        orders.GetItem(auth.user.userToken).then(function(response){ 
            setLoad(false)
            console.log(response)
        }).catch(function(error){
            alert("error")
        })
    },[])

    if(load ){
        return(
            <div></div>
        )
    }


    return( 
        <div className="row">
            <div className="col-md-6">
                <div className="item-wrapper">
                    <h2>Данные заказа</h2>
                    <div className="detail-order">
                        <p>
                            <b>Адрес доставки:</b> {orders.item?.address}<br/>
                        </p>
                        <p>
                            <b>Цена:</b> {orders.item?.finalPrice} руб. (Цена 1/л: {orders.item?.price} руб.)<br/>
                        </p>
                        <p>
                            <b>Стоимость доставки:</b> {orders.item?.deliveryPrice} руб.<br/>
                        </p>
                        <p>
                            <b>Вид топлива:</b> {orders.item?.rate.title} ({orders.item?.volume}л)<br/>
                        </p>
                        <p>
                            <b>Статус:</b> {orders.item?.status}<br/>
                        </p>
                        <p>
                            <b>Комментарий:</b> {orders.item?.comment}<br/>
                        </p>
                            {
                                orders.item?.review != 0 ? (
                                    <p>
                                        <b>Оценка:</b> {orders.item?.review}<br/>
                                    </p>
                                ):""
                            }

                        <hr/>
                        <p>
                            <b>Дополнительные товары</b>
                        </p>
                        {
                            orders.item?.product ? (
                                orders.item.product.length ? (
                                    orders.item.product.map((item, number) => {
                                        return(
                                            <p style={{marginBottom: 10, paddingBottom: 10, borderBottom: "1px solid rgba(0,0,0,0.1)"}}>
                                                <b>Название: </b>{item.name}<br/>
                                                <b>Цена: </b>{item.cost}<br/>

                                                <b>Кол-во: </b>{item.count}<br/>
                                            </p>
                                        )
                                    })  
                                ):""
                            ):""
                        }
                    </div>
                </div>

                <div className="item-wrapper">
                    <h2>Клиент</h2>
                    <div className="mp-orders__header">
                        <div className="row">
                            <div className="col-md-2">
                                id
                            </div>
                            <div className="col-md-4">
                                Доставщик
                            </div>
                            <div className="col-md-3">
                                Контакты
                            </div>
                            <div className="col-md-3"> 
                            </div>
                        </div>
                    </div>

                    <div className="mp-orders__list">
                        <div className="mp-orders__item">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="grid">
                                        <div>#{orders.item?.customers.pk}</div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="grid">
                                        <div className="mp-orders__image-block"> 
                                            <div className="mp-orders__data">
                                                <div className="grid">
                                                    <div>
                                                        {orders.item?.customers.fName} {orders.item?.customers.lName}<br/> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="grid">
                                        <div>
                                            <a href={"tel"+orders.item?.customers.phone}>{orders.item?.customers.phone}</a><br/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="grid">
                                        <a className="hover-btn" href={"/customers/"+orders.item?.customers.pk+"/"}>Подробнее</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {orders.item?.supplier.pk != 0 ? (
                    <div className="item-wrapper">
                        <h2>Доставщик</h2>
                        <div className="mp-orders__header">
                            <div className="row">
                                <div className="col-md-2">
                                    id
                                </div>
                                <div className="col-md-4">
                                    Доставщик
                                </div>
                                <div className="col-md-3">
                                    Контакты
                                </div>
                                <div className="col-md-3"> 
                                </div>
                            </div>
                        </div>

                        <div className="mp-orders__list">
                            <div className="mp-orders__item">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="grid">
                                            <div>#{orders.item?.supplier.pk}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="grid">
                                            <div className="mp-orders__image-block"> 
                                                <div className="mp-orders__data">
                                                    <div className="grid">
                                                        <div>
                                                            {orders.item?.supplier.fio}<br/> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grid">
                                            <div>
                                                <a href={"tel:"+orders.item?.supplier.phone}>{orders.item?.supplier.phone}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="grid">
                                            <a className="hover-btn" href={"/customers/"+orders.item?.supplier.pk+"/"}>Подробнее</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                ):""}
            </div>
            <div className="col-md-6">
                <div className="item-wrapper order-address">
                    <h2>Адрес доставки</h2>
                    {
                        orders.item ? (
                            <YMaps>
                                <Map
                                    defaultState={{
                                        center: [orders.item!.latitude, orders.item!.longitude],
                                        zoom: 16,
                                    }}
                                    height="400px"
                                    width="100%"
                                >
                                    <Placemark geometry={[orders.item!.latitude, orders.item!.longitude]} />
                                </Map>
                            </YMaps>
                        ) : ""
                    }
                </div>
            </div>
        </div>
    )
}
 